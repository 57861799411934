import React from "react";
import {
  AppBar,
  Box,
  Button,
  Container,
  IconButton,
  makeStyles,
  Toolbar
} from "@material-ui/core";
import { Link } from "react-router-dom";
import clsx from "clsx";
import SettingsIcon from "@material-ui/icons/Settings";
import SupervisorAccountIcon from "@material-ui/icons/SupervisorAccount";

import { ROLES, useAuth } from "../../auth";
import Logout from "../Logout";
import I18nText from "../../elements/I18nText";
import Search from "./Search";
import WhitelabeledHeader from "../Whitelabeling/WhitelabeledHeader";
import WhitelabeledTopBarLogo from "../Whitelabeling/WhitelabeledTopBarLogo";
import LanguageSwitcher from "../../elements/LanguageSwitcher";

const useStyles = makeStyles(theme => ({
  appBar: {
    background: theme.custom.topBar.background
  },
  menuItem: {
    height: 48,
    borderRadius: 0,
    fontSize: 14,
    fontWeight: "normal",
    "&:hover": {
      color: theme.palette.common.white,
      backgroundColor: theme.custom.topBar.menuItem.backgroundColorHighlight
    }
  },
  menuItemFixedWidth: {
    width: 90
  },
  menuItemSelected: {
    backgroundColor: theme.custom.topBar.menuItem.backgroundColorHighlight
  }
}));

const TopBar = ({ onSelect, onNavigateHome, selected }) => {
  const classes = useStyles();
  const { user, hasRole } = useAuth();

  return (
    <>
      <WhitelabeledHeader />
      <AppBar position="static" className={classes.appBar} elevation={0}>
        <Container
          maxWidth={false}
          style={{ maxWidth: 1440, paddingLeft: 0, paddingRight: 0 }}
        >
          <Toolbar variant="dense" style={{ paddingLeft: 0, paddingRight: 0 }}>
            <Box width={210} textAlign="center">
              <WhitelabeledTopBarLogo onClick={onNavigateHome} />
            </Box>

            <Button
              className={clsx([
                classes.menuItem,
                classes.menuItemFixedWidth,
                selected === "devices" ? classes.menuItemSelected : ""
              ])}
              component={Link}
              color="inherit"
              type="button"
              to="/devices"
              onClick={() => onSelect("devices")}
            >
              <I18nText>topbar.devices</I18nText>
            </Button>
            {hasRole(user, ROLES.ADMIN) && (
              <Button
                className={clsx([
                  classes.menuItem,
                  classes.menuItemFixedWidth,
                  selected === "users" ? classes.menuItemSelected : ""
                ])}
                component={Link}
                color="inherit"
                type="button"
                to="/users"
              >
                <I18nText>topbar.users</I18nText>
              </Button>
            )}
            {hasRole(user, ROLES.ADMIN) && (
              <Button
                className={clsx([
                  classes.menuItem,
                  classes.menuItemFixedWidth,
                  selected === "messages" ? classes.menuItemSelected : ""
                ])}
                component={Link}
                color="inherit"
                type="button"
                to="/messages"
              >
                <I18nText>topbar.messaging</I18nText>
              </Button>
            )}
            {hasRole(user, ROLES.MASTER) && (
              <Button
                className={clsx([
                  classes.menuItem,
                  classes.menuItemFixedWidth,
                  selected === "system" ? classes.menuItemSelected : ""
                ])}
                component={Link}
                color="inherit"
                type="button"
                to="/system"
              >
                <I18nText>topbar.system</I18nText>
              </Button>
            )}
            <div style={{ flexGrow: 1 }}></div>
            <Search />

            <LanguageSwitcher />
            {hasRole(user, ROLES.USER) && (
              <IconButton
                className={clsx([
                  classes.menuItem,
                  selected === "contacts" ? classes.menuItemSelected : ""
                ])}
                component={Link}
                color="inherit"
                type="button"
                to="/contacts"
              >
                <SupervisorAccountIcon />
              </IconButton>
            )}
            {hasRole(user, ROLES.USER) && (
              <IconButton
                className={clsx([
                  classes.menuItem,
                  selected === "settings" ? classes.menuItemSelected : ""
                ])}
                component={Link}
                color="inherit"
                type="button"
                to="/settings"
              >
                <SettingsIcon />
              </IconButton>
            )}
            <Box className={classes.menuItem}>
              <Logout />
            </Box>
          </Toolbar>
        </Container>
      </AppBar>
    </>
  );
};

export default TopBar;
