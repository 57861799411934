import React from "react";
import { Box, Divider, Typography } from "@material-ui/core";
import I18nText from "../I18nText";

const LoginOrSeparator = () => {
  return (
    <Box display="flex" flexDirection="row" alignItems="center" my={3}>
      <Divider
        style={{
          flexGrow: 1,
          height: "1px",
          marginRight: "1rem"
        }}
      />
      <Typography
        sx={{
          mx: 10,
          fontWeight: "bold",
          color: "gray"
        }}
      >
        <I18nText>login.or</I18nText>
      </Typography>
      <Divider
        style={{
          flexGrow: 1,
          height: "1px",
          marginLeft: "1rem"
        }}
      />
    </Box>
  );
};

export default LoginOrSeparator;
