import React, { useState } from "react";
import { Button, Menu, MenuItem } from "@material-ui/core";
import ArrowDropDownIcon from "@material-ui/icons/ArrowDropDown";
import useLocale from "../i18n/useLocale";
import Flag from "react-world-flags";

const languages = [
  { code: "deu", locale: "de", label: "DE", flag: "🇩🇪" },
  { code: "gbr", locale: "en", label: "EN", flag: "🇬🇧" }
];

const LanguageSwitcher = ({ shouldShowOutline }) => {
  const [anchorEl, setAnchorEl] = useState(null);
  const { setLocale, locale } = useLocale();
  const [selectedLang, setSelectedLang] = useState(
    languages.find(l => l.locale === locale)
  );
  const handleClick = event => {
    setAnchorEl(event.currentTarget);
  };

  const updateLocale = language => {
    if (language) {
      setSelectedLang(language);
      setLocale(language.locale);
    }
    setAnchorEl(null);
  };

  return (
    <div>
      <Button
        onClick={handleClick}
        variant={shouldShowOutline ? "outlined" : "text"}
        style={{
          position: "relative",
          padding: "7px",
          fontSize: 12,
          borderColor: "rgba(0,0,0,0.1)",
          borderRadius: "5px",
          color: shouldShowOutline ? "black" : "white"
        }}
        startIcon={
          <Flag
            code={selectedLang.code}
            height="10"
            fallback={
              <span style={{ fontSize: "0.6rem" }}>{selectedLang.flag}</span>
            }
          />
        }
        endIcon={<ArrowDropDownIcon style={{ padding: "inherit" }} />}
      >
        {selectedLang.label}
      </Button>

      <Menu
        anchorEl={anchorEl}
        keepMounted
        open={Boolean(anchorEl)}
        onClose={() => updateLocale(null)}
      >
        {languages.map(lang => (
          <MenuItem
            key={lang.code}
            onClick={() => updateLocale(lang)}
            selected={lang.code === selectedLang.code}
          >
            <div
              style={{
                display: "flex",
                alignItems: "center",
                gap: "5px",
                fontSize: 12,
                fontWeight: lang.code === selectedLang.code ? "bold" : "normal"
              }}
            >
              <Flag
                code={lang.code}
                height="10"
                fallback={
                  <span style={{ fontSize: "0.6rem" }}>
                    {selectedLang.flag}
                  </span>
                }
              />
              {lang.label}
            </div>
          </MenuItem>
        ))}
      </Menu>
    </div>
  );
};
export default LanguageSwitcher;
